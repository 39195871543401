<template>
  <div id="customersContainer">
    <vs-breadcrumb class="mb-5">
      <li><router-link to="/">Domov</router-link><span class="vs-breadcrum--separator">/</span></li>
      <li aria-current="page" class="active">Uporabniki</li>
    </vs-breadcrumb>


    <vs-button radius size="large" color="success" icon-pack="feather" icon="icon-plus-circle" id="addFabBtn" title="Dodaj novega uporabnika" @click.stop="addCustomer()"></vs-button>

    <div>

      <vs-tabs v-if="activeCustomersData && inactiveCustomersData && allCustomersData">
        <vs-tab label="Aktivni" icon="check_circle" color="success" @click="">
          <CustomersTable :customersData="activeCustomersData" :filter="'active'" @showCustomerDetails="showCustomerDetails" />
        </vs-tab>
        <vs-tab label="Neaktivni" icon="radio_button_unchecked" @click="">
          <CustomersTable :customersData="inactiveCustomersData" :filter="'inactive'" @showCustomerDetails="showCustomerDetails" />
        </vs-tab>
        <vs-tab label="Vsi uporabniki v izbrani enoti" icon="done_all" @click="">
          <CustomersTable :customersData="allCustomersData" :filter="'all'" @showCustomerDetails="showCustomerDetails" />
        </vs-tab>
<!--        <vs-tab label="Vsi uporabniki v izbrani enoti - zemljevid" icon="map" @click="" @v-if="$globalFunctions.isAdmin()">-->
<!--          <CustomersOnMap :customersData="allCustomersData"></CustomersOnMap>-->
<!--        </vs-tab>-->
      </vs-tabs>
    </div>

    <div id="parentx">
      <vs-sidebar position-right parent="body" default-index="1" color="primary" class="customerSidebar" spacer
                  v-model="sidebarActive">

        <Customer v-if="customerId && sidebarActive" :customerId="customerId" />
      </vs-sidebar>
    </div>

  </div>
</template>

<script>
  import Vue from 'vue';

  import CustomersTable from "../../components/Sopotniki/customers/CustomersTable";
  import Customer from "../../components/Sopotniki/customers/Customer";
  import CustomersOnMap from "@/components/Sopotniki/customers/CustomersOnMap"


  export default {
    name: 'customers',

    components: {
      Customer,
      CustomersTable,
      CustomersOnMap
    },

    data() {
      return {
        activeCustomersData: null,
        inactiveCustomersData: null,
        allCustomersData: null,
        customerId: null,
        sidebarActive: false,
        maxItems: 300
      }
    },

    computed: {

    },

    mounted() {
      const _this = this;
      _this.getCustomers();
    },

    beforeRouteLeave(to, from, next) {
      console.log("beforeRouteLeave");
      const _this = this;
      _this.customerId = null;
      _this.sidebarActive = false;
      setTimeout(() => {next()}, 100);
    },

    methods: {
      getCustomers() {
        const _this = this;

        _this.$vs.loading();


        Vue.prototype.$http.get(`${Vue.prototype.$config.api.sopotnikiVPS}customers?unit=${_this.$globalFunctions.getCurrentUnit().id}`)
        //Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'customers/unit/' + _this.$globalFunctions.getCurrentUnit().id)
          .then((res) => {

            _this.allCustomersData = res.data.data;
            _this.activeCustomersData = _.filter(res.data.data, {active: 1});
            _this.inactiveCustomersData = _.filter(res.data.data, {active: 0});
            console.log("TOLE: ", res.data.data);


            _this.$vs.loading.close();


          })
          .catch((error) => {
            console.log("error", error);
            _this.$vs.loading.close();
            return false
          });
      },

      showCustomerDetails(tr) {
        const _this = this;

        console.log("HERE!", tr);

        _this.customerId = parseInt(tr.id);
        _this.sidebarActive = true;
      },

      addCustomer() {
        const _this = this;

        _this.$router.push({name: 'Uporabnik'})
      }

    }
  }
</script>

