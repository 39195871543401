<template>

  <div>
    <gmap-map ref="map" :center="center" :zoom="15" style="width: 100%; height: 500px">

      <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false"/>
      <GmapMarker
        :key="index"
        v-for="(item, index) in markers"
        :position="item.position"
        :clickable="true"
        :draggable="false"
      />
    </gmap-map>
  </div>

</template>

<script>
  import Vue from 'vue';
  import sir from '../../../assets/images/portrait/sir.svg'
  import madam from '../../../assets/images/portrait/madam.svg'


  let directionsService = null;
  let directionsDisplay = null;
  let geoCoder = null;

  export default {
    name: 'customers-on-map',
    props: {
      customersData: Array,
    },
    components: {

    },

    data() {
      return {
        maxItems: 500,
        sir: sir,
        madam: madam,

        center: {lat: 46.0569, lng: 14.5058},
        markers: [],
      }
    },

    computed: {},

    mounted() {
      const _this = this;

      _this.generateMarkers()

    },

    destroyed() {
      console.log("destroyed");
      const _this = this;

    },

    beforeDestroy() {
      console.log("beforeDestroy");
      const _this = this;


    },

    methods: {
      generateMarkers() {
        const _this = this;

        // _.each(_this.customersData, function (val,key){
        //   console.log("val", val);
        //   setTimeout(() => {
        //     _this.showCustomerMarker(val.addresses);
        //   },500*key);
        // });

      },

      showCustomerMarker(address) {
        const _this = this;

        console.log("address", address);

        geoCoder = new google.maps.Geocoder();
        geoCoder.geocode({'address': address.quick_search}, function (results, status) {
          if (status === 'OK') {
            _this.center = results[0].geometry.location;

            console.log("_this.center", results[0].geometry.location);

            let position = {
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
            }

            _this.markers.push({
              infoText: '',
              position: position
            });

          } else {
            console.error('Geocode was not successful for the following reason: ' + status);
          }
        });

      },


      showCustomerDetails(tr) {
        const _this = this;

        _this.$emit('showCustomerDetails', tr);
      },

      getUnitDataById(unitId) {
        const _this = this;

        if(localStorage.getItem('userInfo')) {
          let unit = _.filter(JSON.parse(localStorage.getItem('userInfo')).units, {id: unitId})[0];
          if (unit) {
            return unit.name + ' (' + unit.coveredMunicipalities + ')';
          } else
            return 'podatka ni bilo mogoče pridobiti';
        }

      },

      stopTheEvent: (event) => {
        event.stopPropagation();
        event.preventDefault();
      },


    }
  }
</script>

